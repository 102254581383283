/*------------------------------------*\
    #FONTS
\*------------------------------------*/

@font-face {
  font-family: "OpenSans-Regular";
  src: url("../../assets/fonts/OpenSans-Regular/fonts/OpenSans-Regular.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/OpenSans-Regular/fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../assets/fonts/OpenSans-Regular/fonts/OpenSans-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../assets/fonts/OpenSans-Regular/fonts/OpenSans-Regular.woff") format("woff"), /* Modern Browsers */
  url("../../assets/fonts/OpenSans-Regular/fonts/OpenSans-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../../assets/fonts/OpenSans-Bold/fonts/OpenSans-Bold.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/OpenSans-Bold/fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../assets/fonts/OpenSans-Bold/fonts/OpenSans-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../assets/fonts/OpenSans-Bold/fonts/OpenSans-Bold.woff") format("woff"), /* Modern Browsers */
  url("../../assets/fonts/OpenSans-Bold/fonts/OpenSans-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Italic";
  src: url("../../assets/fonts/OpenSans-Italic/fonts/OpenSans-Italic.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/OpenSans-Italic/fonts/OpenSans-Italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../assets/fonts/OpenSans-Italic/fonts/OpenSans-Italic.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../assets/fonts/OpenSans-Italic/fonts/OpenSans-Italic.woff") format("woff"), /* Modern Browsers */
  url("../../assets/fonts/OpenSans-Italic/fonts/OpenSans-Italic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Light";
  src: url("../../assets/fonts/OpenSans-Light/fonts/OpenSans-Light.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/OpenSans-Light/fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../assets/fonts/OpenSans-Light/fonts/OpenSans-Light.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../assets/fonts/OpenSans-Light/fonts/OpenSans-Light.woff") format("woff"), /* Modern Browsers */
  url("../../assets/fonts/OpenSans-Light/fonts/OpenSans-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("../../assets/fonts/OpenSans-SemiBold/fonts/OpenSans-SemiBold.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/OpenSans-SemiBold/fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../assets/fonts/OpenSans-SemiBold/fonts/OpenSans-SemiBold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../assets/fonts/OpenSans-SemiBold/fonts/OpenSans-SemiBold.woff") format("woff"), /* Modern Browsers */
  url("../../assets/fonts/OpenSans-SemiBold/fonts/OpenSans-SemiBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../../assets/fonts/Lato-Bold/fonts/Lato-Bold.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/Lato-Bold/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../assets/fonts/Lato-Bold/fonts/Lato-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../assets/fonts/Lato-Bold/fonts/Lato-Bold.woff") format("woff"), /* Modern Browsers */
  url("../../assets/fonts/Lato-Bold/fonts/Lato-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Italic";
  src: url("../../assets/fonts/Lato-Italic/fonts/Lato-Italic.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/Lato-Italic/fonts/Lato-Italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../assets/fonts/Lato-Italic/fonts/Lato-Italic.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../assets/fonts/Lato-Italic/fonts/Lato-Italic.woff") format("woff"), /* Modern Browsers */
  url("../../assets/fonts/Lato-Italic/fonts/Lato-Italic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../../assets/fonts/Lato-Regular/fonts/Lato-Regular.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fonts/Lato-Regular/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../assets/fonts/Lato-Regular/fonts/Lato-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../assets/fonts/Lato-Regular/fonts/Lato-Regular.woff") format("woff"), /* Modern Browsers */
  url("../../assets/fonts/Lato-Regular/fonts/Lato-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
