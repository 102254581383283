@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?nnx3jv');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?nnx3jv#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?nnx3jv') format('woff2'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?nnx3jv') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?nnx3jv') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?nnx3jv##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clear {
  &:before {
    content: $icon-clear;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-info-circle {
  &:before {
    content: $icon-info-circle;
  }
}

.icon-autorenew {
  &:before {
    content: $icon-autorenew;
  }
}

.icon-baseline-create-24px {
  &:before {
    content: $icon-baseline-create-24px;
  }
}

.icon-test-tube-empty {
  &:before {
    content: $icon-test-tube-empty;
  }
}

.icon-undo {
  &:before {
    content: $icon-undo;
  }
}

.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}

.icon-ic_eb-lb {
  &:before {
    content: $icon-ic_eb-lb;
  }
}

.icon-ic_eb {
  &:before {
    content: $icon-ic_eb;
  }
}

.icon-ic_lb {
  &:before {
    content: $icon-ic_lb;
  }
}

.icon-settings {
  &:before {
    content: $icon-settings;
  }
}

.icon-barn {
  &:before {
    content: $icon-barn;
  }
}

.icon-cow {
  &:before {
    content: $icon-cow;
  }
}

.icon-gender-male {
  &:before {
    content: $icon-gender-male;
  }
}

.icon-tag {
  &:before {
    content: $icon-tag;
  }
}

.icon-comment {
  &:before {
    content: $icon-comment;
  }
}

.icon-cow-1 {
  &:before {
    content: $icon-cow-1;
  }
}

.icon-ic_save_done {
  &:before {
    content: $icon-ic_save_done;
  }
}

.icon-ic_save_plus {
  &:before {
    content: $icon-ic_save_plus;
  }
}

.icon-plus {
  &:before {
    content: $icon-plus;
  }
}

.icon-toolbox {
  &:before {
    content: $icon-toolbox;
  }
}

.icon-ic_inspect {
  &:before {
    content: $icon-ic_inspect;
  }
}

.icon-magnify {
  &:before {
    content: $icon-magnify;
  }
}

.icon-account {
  &:before {
    content: $icon-account;
  }
}

.icon-baseline-arrow_drop_down-24px {
  &:before {
    content: $icon-baseline-arrow_drop_down-24px;
  }
}

.icon-baseline-chevron_left-24px {
  &:before {
    content: $icon-baseline-chevron_left-24px;
  }
}

.icon-baseline-delete-24px {
  &:before {
    content: $icon-baseline-delete-24px;
  }
}

.icon-baseline-done-24px {
  &:before {
    content: $icon-baseline-done-24px;
  }
}

.icon-baseline-event-24px {
  &:before {
    content: $icon-baseline-event-24px;
  }
}

.icon-baseline-first_page-24px {
  &:before {
    content: $icon-baseline-first_page-24px;
  }
}

.icon-baseline-last_page-24px {
  &:before {
    content: $icon-baseline-last_page-24px;
  }
}

.icon-Gruppe-maskieren-1 {
  &:before {
    content: $icon-Gruppe-maskieren-1;
  }
}

.icon-plus-circle {
  &:before {
    content: $icon-plus-circle;
  }
}

.icon-plus-circle-1 {
  &:before {
    content: $icon-plus-circle-1;
  }
}

.icon-vost_logo_white {
  &:before {
    content: $icon-vost_logo_white;
  }
}