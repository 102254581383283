$icomoon-font-family: "moda" !default;
$icomoon-font-path: "/assets/icon-fonts" !default;

$icon-clear: "\e921";
$icon-close: "\e921";
$icon-info-circle: "\e922";
$icon-autorenew: "\e91d";
$icon-baseline-create-24px: "\e91e";
$icon-test-tube-empty: "\e91f";
$icon-undo: "\e920";
$icon-refresh: "\e91c";
$icon-ic_eb-lb: "\e91b";
$icon-ic_eb: "\e918";
$icon-ic_lb: "\e919";
$icon-settings: "\e91a";
$icon-barn: "\e90f";
$icon-cow: "\e915";
$icon-gender-male: "\e916";
$icon-tag: "\e917";
$icon-comment: "\e90e";
$icon-cow-1: "\e910";
$icon-ic_save_done: "\e911";
$icon-ic_save_plus: "\e912";
$icon-plus: "\e913";
$icon-toolbox: "\e914";
$icon-ic_inspect: "\e90c";
$icon-magnify: "\e90d";
$icon-account: "\e900";
$icon-baseline-arrow_drop_down-24px: "\e901";
$icon-baseline-chevron_left-24px: "\e902";
$icon-baseline-delete-24px: "\e903";
$icon-baseline-done-24px: "\e904";
$icon-baseline-event-24px: "\e905";
$icon-baseline-first_page-24px: "\e906";
$icon-baseline-last_page-24px: "\e907";
$icon-Gruppe-maskieren-1: "\e908";
$icon-plus-circle: "\e909";
$icon-plus-circle-1: "\e90a";
$icon-vost_logo_white: "\e90b";

