/*-------------------------------*/
/* MODAL STYLES

  [1] modals are hidden by default
  [2] modal container fixed across whole screen
  [3] z-index must be higher than .modal-background
  [4] enables scrolling for tall modals
  [5] modal background fixed across whole screen
  [6] semi-transparent black
  [7] z-index must be below .modal and above everything else
*/
/*-------------------------------*/

$modal-spacing: $SPACING !default;
$modal-spacing-tiny: $SPACING-TINY !default;
$modal-color-blue: $COLOR-PRIMARY !default;
$modal-color-white: $COLOR-WHITE !default;
$modal-color-grey: $COLOR-DARK-LIGHTEN !default;
$modal-icon-size: ms(1) !default;

.portal-modal {
  /* [1] */
  display: none;

  .c-modal {
    /* [2] */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 2px 0 10px 0 $modal-color-grey;
    /* [3] */
    z-index: 900;
    min-width: 600px;

    /* [4] */
    overflow: auto;

    .c-modal__body {
      background: $modal-color-white;
    }
  }

  .c-modal__header {
    display: flex;
    background-color: $modal-color-blue;
    padding: $modal-spacing-tiny $modal-spacing;

    .c-modal__headline {
      justify-content: flex-start;
      margin: 0;
      color: $modal-color-white;
    }

    .c-modal__close-button {
      background-color: transparent;
      border: 0;
      margin-left: auto;
      cursor: pointer;
    }

    .c-model__cross {
      color: $modal-color-white;

      &::before {
        font-size: $modal-icon-size;
      }
    }
  }

  .c-modal-background {
    /* [5] */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* [6] */
    background-color: $modal-color-white;
    opacity: 0.7;

    /* [7] */
    z-index: 800;
  }
}

body.c-modal-open {
  overflow: auto;
}

$clearButtonWidth: 12px;

.clear-btn {
  width: $clearButtonWidth;
  min-width: $clearButtonWidth;
  max-width: $clearButtonWidth;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $SEARCH-BORDER-COLOR;
}

.form-content-modal {
  display: flex;
  flex-flow: row;
  margin: 0 20px 20px;

  .right-button {
    margin-left: auto;
  }
}
