/*------------------------------------*\
    #CONTROL ELEMENTS
\*------------------------------------*/

$control-spacing-tiny: $SPACING-TINY !default;
$control-spacing: $SPACING !default;

$control-color-grey: $COLOR-GREY !default;

/*----------BUTTONS----------*/

.c-mat-button {
  height: 48px;
  font-size: 14px;

  span {
    font-size: 14px;
  }
}

.green-button {
  background-color: $BUTTON-PRIMARY-BACKGROUND;
  color: $COLOR-WHITE;
}

.dark-green-button {
  background-color: #007B34;
  color: $COLOR-WHITE;
}

.no-color-button {
  color: #555555;
}

/*------------ICONS-----------*/

.c-icon {
  margin: 0 $control-spacing-tiny;
  cursor: pointer;
}

.c-inline {
  display: flex;
  flex-flow: row;
}

.margin-right-left-auto {
  margin-left: auto !important;
}

.c-output-container {
  display: inline-flex;
  margin-top: 10px;

  label {
    width: 100px;
    margin: 0 35px;
    font-family: $base-font-family-bold;
    font-size: 1em;
    color: #555555;
  }

  output {
    min-width: 250px;
    font-size: 1em;
  }
}

/*------------MATERIAL-----------*/

.mat-grid-tile .mat-figure {
  justify-content: flex-start !important;
}

.mat-calendar-body-selected {
  background-color: #007B34;
}

.mat-mdc-text-field-wrapper {
  padding-left: 0 !important;
}

.mdc-text-field {
  background-color: inherit !important;
}

.mdc-text-field--focused {
  background-color: white !important;
}

/*------------ETC-----------*/

.right-cell-header {
  width: 100%;
  text-align: right;
}

.center-cell-header {
  width: 100%;
  text-align: center;
}

.right-cell {
  text-align: right;

  &:last-child {
    padding-right: 13px;
  }
}

.pointer {
  cursor: pointer;
}

.action-icon {
  font-size: 20px;
  cursor: pointer;
  color: #555555;
}

.action-icon:hover {
  color: #007B34;
}
