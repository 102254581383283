/******************************************************************
*                     ANGULAR MATERIAL THEMING                    *
******************************************************************/
/**
 *    Custom Theming for Angular Material
 *    For more information: https://material.angular.io/guide/theming
 *
 */

@use '@angular/material' as mat;

@include mat.core();

$md-moda-primary: (
    50 : #e0efe7,
    100 : #b3d7c2,
    200 : #80bd9a,
    300 : #4da371,
    400 : #268f52,
    500 : #007b34,
    600 : #00732f,
    700 : #006827,
    800 : #005e21,
    900 : #004b15,
    A100 : #7fff94,
    A200 : #4cff6a,
    A400 : #19ff40,
    A700 : #00fe2b,
    contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
    )
  );

$moda-theme-primary: mat.define-palette($md-moda-primary, 500, 400, 600);

$moda-frontend-theme: mat.define-light-theme((
  color: (
    primary: $moda-theme-primary,
    accent: $moda-theme-primary
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($moda-frontend-theme);
