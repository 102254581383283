/*------------------------------------*\
    #COLOR SETTINGS
\*------------------------------------*/

$COLOR-PRIMARY: #c03a88;
$COLOR-SECONDARY: #5DACD9;
$COLOR-TERTIARY: #FE4A49;
$COLOR-QUATERNARY: #FFCF56;
$COLOR-QUINARY: #35A148;
$COLOR-DARK: #262626;
$COLOR-DARK-LIGHT: #666666;
$COLOR-DARK-LIGHTEN: #929292;
$COLOR-GREY: #EEEEEE;
$COLOR-GREY-LIGHT: #F5F5F5;
$COLOR-WHITE: #FFFFFF;
$COLOR-VALIDATION-ERROR: #C0002C;
$COLOR-GREEN: #007B34;
$COLOR-GREEN-LIGHT: #0D823E;

$BORDER-COLOR: #dedede;
$BORDER-SHADOW-COLOR: #717b8c;
$CONTENT-BACKGROUND: #f7f7f7;
$SEARCH-BORDER-COLOR: #717b8c;
$BUTTON-PRIMARY-BORDER: #98b500;
$BUTTON-PRIMARY-BACKGROUND: #7CB342;
$BUTTON-GREY-BACKGROUND: rgba(39, 39, 39, 0.25);


/*------------------------------------*\
    #FONT SETTINGS
\*------------------------------------*/

$GLOBAL-FONT-SIZE: 14px; // define font-size in px for easier setting

$BASE-FONT: Roboto, "Helvetica Neue", sans-serif;
$BASE-FONT-ITALIC: "Lato-Italic";
$BASE-FONT-BOLD: "Lato-Bold";

$BASE-FONT-SECONDARY: "OpenSans-Regular";
$BASE-FONT-BOLD-SECONDARY: "OpenSans-Bold";
$BASE-FONT-SEMI-BOLD-SECONDARY: "OpenSans-SemiBold";
$BASE-FONT-ITALIC-SECONDARY: "OpenSans-Italic";
$BASE-FONT-LIGHT-SECONDARY: "OpenSans-Light";

/*------------------------------------*\
    #SPACING SETTINGS
\*------------------------------------*/

$FACTOR-TINY: 0.25;
$FACTOR-SMALL: 0.5;
$FACTOR-LARGE: 2;
$FACTOR-HUGE: 4;

$SPACING: $GLOBAL-FONT-SIZE * 1.5;
$SPACING-TINY: $SPACING * $FACTOR-TINY;
$SPACING-SMALL: $SPACING * $FACTOR-SMALL;
$SPACING-LARGE: $SPACING * $FACTOR-LARGE;
$SPACING-HUGE: $SPACING * $FACTOR-HUGE;

/*
  Sass-mq config
 */

$mq-breakpoints: (
  mobile: 576px,
  tablet: 768px,
  desktop: 992px,
  wide: 1200px
)
