/*------------------------------------*\
    #DATA TABLE
    Styles to override the ngx-data-table styles.
    Deep Selectors are necessary to override these style without important property.
    [1] pageSize-changer via selectBox.
\*------------------------------------*/

$table-color-white: $COLOR-WHITE !default;
$table-color-grey-light: $COLOR-GREY-LIGHT !default;
$table-color-grey: $COLOR-DARK-LIGHTEN !default;
$table-color-dark: $COLOR-DARK !default;
$table-color-background: $COLOR-GREY !default;
$table-spacing: $SPACING !default;
$table-spacing-tiny: $SPACING-TINY !default;
$table-spacing-tiny-double: $SPACING-TINY * 2 !default;
$table-cell-padding: $table-spacing !default;
$table-cell-height: 50px !default;
$table-footer-height: 57px !default;
$table-footer-page-radius: 50px !default;
$table-group-header-height: 48px !default;
$table-font-regular: $BASE-FONT-SECONDARY !default;
$table-font-bold: $BASE-FONT-BOLD !default;
$table-font-italic: $BASE-FONT-ITALIC !default;
$table-radius: 6px !default;
$table-checkbox-size: 16px !default;

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.c-data-list {

  margin: 0 0 15px;

  .c-data-list__table {

    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    overflow: visible;

    .datatable-header {
      background-color: $table-color-white;
      border: 0;

      .datatable-header-cell-template-wrap {
        position: relative;

        .sort-btn {
          display: flex;
          align-items: center;
          margin-left: $table-spacing-tiny;
          margin-top: $table-spacing-tiny-double;
          margin-right: $table-spacing-tiny;
          color: $table-color-dark;
          transform: translateY(-50%);
        }
      }

      .datatable-header-inner {
        height: 100%;
      }

      .datatable-header-cell {
        padding: 0;
        height: 100%;
        position: relative;
        border-bottom: 1px solid $table-color-grey;

        &:not(:last-child) {
          border-right: 1px solid $control-color-grey;
        }
      }

      .datatable-header-cell-wrapper {
        display: block;
        height: 100%;
      }

      .c-data-list__header--without-filter,
      .c-data-list__header--default {
        padding: 0 0 0 $table-spacing-tiny-double;
      }

      .c-data-list__header--default {
        .datatable-header-cell-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .datatable-header-cell-label {
        color: $table-color-dark;
        width: 100%;
        padding: $table-cell-padding 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .c-data-list__header--without-filter {

        .datatable-header-cell-template-wrap {
          .datatable-header-cell-wrapper {
            cursor: inherit;
          }
        }

        .datatable-header-cell-label {
          display: block;
          height: 100%;
          padding: 0;
          cursor: pointer;
        }
      }
    }

    .datatable-body {
      position: absolute;
      top: 57px;
      left: 0;
      right: 0;
      bottom: 57px;

      overflow-x: hidden;
      overflow-y: auto;
      background: $COLOR-WHITE;

      .datatable-body-row {

        max-width: 100%;
        min-width: 100%;

        &:hover, &:focus {
          background-color: $table-color-background;
        }

        &.active .datatable-row-group {
          background-color: #F2F8F5;
        }

        .datatable-body-cell {
          display: flex;
          align-items: center;
          padding: 0.75em;
          border-bottom: 1px solid $table-color-grey;

          .datatable-body-cell-label, .datatable-body-cell-label > div {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              font-family: $table-font-regular;
            }
          }
        }

        .decrementIncrementCell {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          .mat-mdc-icon-button {
            width: auto;
            padding: 0;
          }

          i {
            font-size: 15px;
            color: #7B7B7B;
            margin-right: 5px;
            margin-left: 5px;
            cursor: pointer;
          }

          input {
            text-align: center;
            max-width: 40px
          }
        }
      }

      .datatable-scroll {
        display: inline;
        max-width: 100%;
        min-width: 100%;
      }

      .empty-row {
        height: 100%;
        line-height: 490px;
        text-align: center;
        color: #555555;
        opacity: 0.5;
      }

      .progress-linear {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        position: absolute;

        .container {
          display: block;
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 100%;

          .bar,
          .bar:before,
          .bar:after {
            border-radius: 50%;
            width: 2.5em;
            height: 2.5em;
            -webkit-animation: load 1.8s infinite ease-in-out;
            -webkit-animation-fill-mode: both;
            animation: load 1.8s infinite ease-in-out;
            animation-fill-mode: both;
          }

          .bar {
            z-index: 100;
            color: #007B34;
            font-size: 10px;
            margin: 215px auto;
            position: relative;
            text-indent: -9999em;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
          }

          .bar:before,
          .bar:after {
            content: '';
            position: absolute;
            top: 0;
          }

          .bar:before {
            left: -3.5em;
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
          }

          .bar:after {
            left: 3.5em;
          }
        }
      }
    }

    .datatable-header-cell-template-wrap {
      display: flex;
      align-items: center;
    }

    .datatable-footer {
      position: absolute;
      z-index: 100;
      bottom: 0;
      background: $COLOR-WHITE;

      .page-count,
      .datatable-pager,
      .result-changer {
        flex-basis: 25%;
        justify-content: flex-end;
        height: $table-footer-height;
        display: flex;
        align-items: center;
        padding: 0 $table-spacing;

        span {
          color: $table-color-dark;
        }
      }

      .page-count {
        flex-basis: 25%;
        justify-content: flex-start;
      }

      .datatable-pager {
        flex-basis: 50%;
        justify-content: center;

        li {

          a {
            @extend .noselect;
            text-align: center;
            min-width: 1.8em;
            min-height: 1.8em;
            padding: 6px;
            text-decoration: none;
            color: $table-color-grey;

            &:visited,
            &:active,
            &:focus {
              color: $table-color-grey;
            }
          }

          &.active a {
            border-radius: $table-footer-page-radius;
            background-color: $COLOR-GREEN-LIGHT;
            color: $table-color-white;
          }

          &:not(.disabled) {
            i {
              color: $COLOR-GREEN-LIGHT;
            }
          }

          &:hover a {
            border-radius: $table-footer-page-radius;
          }
        }

        .result-changer {
          flex-basis: 25%;
          justify-content: flex-end;

          .c-data-list__result-text {
            padding-right: $table-spacing-tiny;
          }
        }
      }
    }
  }

  /* [1] */
  .c-data-list__select-results-wrapper {
    position: relative;

    .c-data-list__select-results {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 0;
      padding-right: $table-spacing;
      cursor: pointer;
      font-family: $table-font-bold;
    }

    .c-data-list__select-results::-ms-expand {
      display: none;
    }

    .c-data-list__footer-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      pointer-events: none;
      color: $COLOR-GREEN;
    }
  }

  .column-right-alignment {
    .datatable-body-cell-label {
      margin-left: auto !important;
    }
  }

  .table-footer {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .c-tooltip-container {
    position: fixed !important;
    top: 40px;
    right: 0;
    padding: 10px 15px;
    background-color: $table-color-white;
    display: none;
    z-index: 20000 !important;;

    .c-button-tooltip {
      display: flex;
      align-items: center;
      min-width: 166px;
      height: 30px;
      color: black;
      border: 0;
      cursor: pointer;
      background-color: $table-color-white;

      &:hover {
        color: $table-color-background;
      }
    }
  }

  .c-tooltip-container--show {
    display: block;
    position: absolute;
    z-index: 2000;
  }

  .c-padding-right-20 {
    padding-right: 20px;
  }
}

.c-data-list__column-first {
  display: flex;
  justify-content: center;
}

.c-data-list__checkbox-column--header {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-left: 8px;

  .c-data-list__checkbox-label {
    margin: 0;
    color: $table-color-dark;
  }
}

.c-data-list__checkbox {
  border-radius: $table-radius;
  height: $table-checkbox-size;
  width: $table-checkbox-size;
}

.c-data-list__checkbox--header {
  margin-top: $table-spacing-tiny;
}

.c-data-list__text-italic {
  font-family: $table-font-italic;
}

.c-data-list__text-bold {
  font-family: $table-font-bold;
}

.c-data-list__column-filter {
  width: 100%;
}

.c-data-list__column-filler {
  display: block;
}

/*----------List grouping----------*/
.c-data-list__group-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: $table-group-header-height;
}

.c-data-list__group-icon {
  width: $table-cell-height;
  text-align: center;
}

.c-data-list__group-text {
  display: block;
  padding-left: $table-spacing-tiny-double;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*----------Multiselect ButtonList----------*/
.c-data-list__multi-action-col {
  display: flex;
}

.c-data-list__multi-button {
  margin-right: $table-spacing;
}

/*----------State Override----------*/

.ngx-datatable.material.striped .datatable-row-odd {
  background: $table-color-background;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: $table-color-background;

  .datatable-body-cell-label {
    color: $table-color-white;

    .datatable-icon-down,
    .datatable-icon-right {
      color: $table-color-white;
    }
  }

  .c-button {
    background-color: $table-color-grey;
  }
}

.ngx-datatable.material.single-selection .datatable-body-row.active:hover,
.ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: $table-color-background;

  .datatable-body-cell-label {
    color: $table-color-white;

    .datatable-icon-down,
    .datatable-icon-right {
      color: $table-color-white;
    }
  }

  .c-button {
    background-color: $table-color-grey;
  }
}

.ngx-datatable.material.single-selection .datatable-body-row.active:focus,
.ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus,
.ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: $table-color-background;

  .datatable-body-cell-label {
    color: $table-color-white;

    .datatable-icon-down,
    .datatable-icon-right {
      color: $table-color-white;
    }
  }

  .c-button {
    background-color: $table-color-grey;
  }
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: $table-color-background;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: $table-color-background;
}

.ngx-datatable.material.cell-selection .datatable-body-cell:hover,
.ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: $table-color-background;
}

.ngx-datatable.material.cell-selection .datatable-body-cell:focus,
.ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: $table-color-background;
}

.ngx-datatable.material.cell-selection .datatable-body-cell.active,
.ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: $table-color-background;

  .datatable-body-cell-label {
    color: $table-color-white;

    .datatable-icon-down,
    .datatable-icon-right {
      color: $table-color-white;
    }
  }

  .c-button {
    background-color: $table-color-grey;
  }
}

.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover,
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: $table-color-background;

  .datatable-body-cell-label {
    color: $table-color-white;

    .datatable-icon-down,
    .datatable-icon-right {
      color: $table-color-white;
    }
  }

  .c-button {
    background-color: $table-color-grey;
  }
}

.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus,
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: $table-color-background;

  .datatable-body-cell-label {
    color: $table-color-white;

    .datatable-icon-down,
    .datatable-icon-right {
      color: $table-color-white;
    }
  }
}

.panel {
  margin: 25px 50px;
  border: 1px solid $BORDER-COLOR;
  -webkit-box-shadow: 0 1px 0 $BORDER-SHADOW-COLOR;
  -moz-box-shadow: 0 1px 0 $BORDER-SHADOW-COLOR;
  box-shadow: 0 1px 0 $BORDER-SHADOW-COLOR;
  display: flex;
  flex-flow: column;

  .titlebar {
    background: $BORDER-COLOR;
    padding: 15px 0 15px 35px;

    .title {
      font-size: 1.3em;
      color: #555555;
    }
  }

  .panel-content {
    background: $CONTENT-BACKGROUND;

    .toolbar {
      margin: 20px 35px 0 35px;
      display: flex;
      flex-flow: row nowrap;

      .toolbar-left {
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;
        align-items: center;
      }

      .buttons {
        flex-grow: 0;
        display: flex;
        flex-flow: row nowrap;
      }
    }

    .buttons-right {
      display: flex;
      flex-flow: row nowrap;
      place-content: flex-end;
      padding: 15px 35px;
    }

    .content-table {
      margin: 0 35px;
    }
  }
}

.icon-btn {
  cursor: pointer;
  color: $COLOR-DARK-LIGHTEN;

  &:hover {
    color: $BUTTON-PRIMARY-BACKGROUND;
  }
}

.btn {
  cursor: pointer;
  border-radius: 4px;
  padding: 15px 15px;
}

.btn-primary {
  border: 1px solid $BUTTON-PRIMARY-BORDER;
  color: $COLOR-WHITE;
  background: $BUTTON-PRIMARY-BACKGROUND;
  margin-right: $table-spacing;
  min-width: 200px;
  max-width: 200px;

  &:hover {
    background: $BUTTON-PRIMARY-BORDER;
    color: $COLOR-WHITE;
  }
}

.btn-cancel {
  color: $COLOR-DARK-LIGHTEN;
  background: $table-color-grey-light;
  margin-right: $table-spacing;
  min-width: 200px;
  max-width: 200px;

  &:hover {
    background: $BUTTON-GREY-BACKGROUND;
    color: $COLOR-WHITE;
  }
}

/**
 * Progress bar animations
 */
@-webkit-keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
