/*------------------------------------*\
    #BASE STYLES
\*------------------------------------*/

$base-font-size: $GLOBAL-FONT-SIZE !default;
$base-font-family: $BASE-FONT !default;
$base-font-family-bold: $BASE-FONT-BOLD !default;
$base-color-blue: $COLOR-PRIMARY !default;
$base-color-blue-hover: $COLOR-SECONDARY !default;
$base-size-circle: 15px !default;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: $base-font-family;
  font-size: $base-font-size;
  min-height: 100%;
  position: relative;
  background: #FAFAFA;

  a {
    color: $base-color-blue;

    &:hover,
    &:active {
      color: $base-color-blue-hover;
    }
  }
}

h1 {
  font-family: $base-font-family-bold;
}

h2 {
  font-family: $base-font-family-bold;
}

h3 {
  font-family: $base-font-family-bold;
}

h4 {
  font-family: $base-font-family-bold;
}

h5 {
  font-family: $base-font-family-bold;
}

h6 {
  font-family: $base-font-family-bold;
}

label, span, td, p, a, input, textarea {
  font-family: $base-font-family;
}
