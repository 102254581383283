.vost-modal-dialog {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {

    .mat-mdc-tab-labels {
      background-color: #FAFAFA;
    }

    .mat-mdc-dialog-content {
      margin: 0;
      padding: 0;
      max-height: 80vh;
      background-color: #FAFAFA;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-content {
      font-size: 1.15em;
    }

    .mat-mdc-tab-group.mat-primary .mat-ink-bar, .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
      background: #7CB342;
    }

    .headline {
      height: 56px;
      background: #F2F2F2;
      display: flex;
      align-items: center;

      label {
        color: #555555;
        font-size: 1.2em;
        margin-left: 35px;
      }
    }

    padding: 0;
  }
}
